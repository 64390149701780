import React from "react";
import logo from "../../assets/logo2.png";
import CardService from "../includes/CardService/CardService";
import cover from "../../assets/internet-ser.png";
import cover1 from "../../assets/linking-ser.png";
import cover2 from "../../assets/support-ser.png";
import cover3 from "../../assets/housing-ser.png";

export default function Services() {
  return (
    <div className="my-20" id="services-section">
      <div className="flex items-center justify-center flex-col">
        <h1 className="text-lg md:text-4xl text-center">Our Services</h1>
        <div className="flex items-center space-x-2 mb-4">
          <div className="h-[2px] mt-2 w-4 bg-[#4ac4f3] rounded-full"></div>
          <div className="h-[2px] mt-2 w-20 bg-[#4ac4f3] rounded-full"></div>
        </div>
        <p className="text-xs md:text-sm text-center ">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Exercitationem vel placeat molestiae rem similique ad!
        </p>
      </div>

      <div className="grid md:grid-cols-4 gap-8 mt-14">
        <CardService
          delay="150"
          title="Internet offers for startups"
          desc="Newton thought that light was made up of particles."
          img={cover}
        />
        <CardService
          delay="200"
          title="Linking startups with the market"
          desc="“Quantum mechanics” is the description of the behaviour of matter"
          img={cover1}
          color={true}
        />
        <CardService
          delay="250"
          title="Supporting startups with technical solutions"
          desc="They describe a universe consisting of bodies moving"
          img={cover2}
        />
        <CardService
          delay="300"
          title="The housing and the hosting services"
          desc="They finally obtained a consistent description of the behaviour "
          img={cover3}
          color={true}
        />
      </div>
    </div>
  );
}
