import React, { useEffect, useState } from "react";
import NavBar from "../Components/NavBar/NavBar";
import logo from "../assets/logo.png";
import forstartup from "../assets/forstartup.png";
import { Link } from "react-router-dom";
import { Main_API } from "../env";
import axios from "axios";
import rocket from "../assets/rocket.png";

export default function Login(props) {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [item, setItem] = useState("");
  useEffect(() => {
    localStorage.removeItem("email");
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${Main_API}/login`,
        {
          phone: phone,
          password: password,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        localStorage.setItem("token", JSON.stringify(response.data.user));
        window.location.href = "/";
      }

      console.log(response.data);
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  return (
    <div className="">
      <NavBar scrolling={props.scrolling} fixed />
      <div className="h-screen pt-20 grid rid-cols-1 justify-center items-center align-center relative overflow-hidden">
        <div className="absolute left-0 right-0 wave1 opacity-40"></div>
        <img
          src={rocket}
          alt=""
          className="absolute right-10 top-1/2 -translate-y-1/2 w-[600px] h-[600px] object-cover"
        />

        <form className="z-30 bg-white h-fit backdrop-blur-2xl bg-opacity-30 shadow-xl p-6 rounded-lg">
          <h1 className="text-4xl font-medium p-2 ">Login to your account</h1>
          <p className="text-gray-400 text-center">Services for your growth.</p>
          <div className="mt-4">
            <input
              type="text"
              name="phone"
              placeholder="Phone Number"
              className="rounded-full px-4 py-3 w-full bg-white border outline-none"
              value={phone}
              required
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="mt-4">
            <input
              type="password"
              name="password"
              placeholder="Password "
              required={true}
              className="rounded-full px-4 py-3 w-full bg-white border outline-none"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mt-4 flex items-center justify-center">
            <button
              type="submit"
              onClick={handleLogin}
              className="bg-[#4ac4f3] text-white px-8 py-2 rounded-lg mt-4"
            >
              Login
            </button>
          </div>
          <Link to="/signup">
            <p className={`text-sm text-center py-2 hover:underline`}>
              Create Startup Account
            </p>
          </Link>
        </form>
      </div>
    </div>
  );
}
