import React, { useState, useEffect } from "react";
import logo from "../../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons"; // Replace with the specific icon you want to use
import { Main_API } from "../../env";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";

export default function NavBar(props) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [Active, setActive] = useState(false);
  const [token, setToken] = useState("");
  useEffect(() => {
    const item = JSON.parse(localStorage.getItem("token"));
    if (item) {
      setToken(item);
    }
  }, []);
  const handleClick = () => {
    setActive(!Active);
  };
  const logout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };
  const dashboard = () => {
    window.location.href =
      "https://backend.delta-innovation.net/public/startup/" + token;
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 400);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navbarClass = isScrolled || props.fixed ? "fixed-navbar" : "";

  const navigate = useNavigate();

  return (
    <>
      <div className={`${navbarClass} border-b`}>
        {/* <TopHeader /> */}
        <div className="grid grid-cols-12 responsive bg-white">
          <Link to={"/"} className="col-span-3 flex items-center space-x-2">
            <img className={`w-20 h-20 object-contain`} src={logo} alt="logo" />
          </Link>

          <div className="col-span-9 grid grid-cols-12">
            <div className="col-span-9 hidden md:flex justify-end items-center">
              <ul className="flex justify-between w-full items-center mr-8 space-x-6 font-medium text-gray-700 text-sm">
                <li className="uppercase cursor-pointer navLinkStyle">
                  <a href="/#home-section">Home</a>
                </li>
                <li className="uppercase cursor-pointer navLinkStyle">
                  <a href="/#about-section">About</a>
                </li>
                <li className="uppercase cursor-pointer navLinkStyle">
                  <a href="/#services-section">Services</a>
                </li>
                <li className="uppercase cursor-pointer navLinkStyle">
                  <a href="/#cat-section">Categories</a>
                </li>

                <li className="uppercase cursor-pointer navLinkStyle">
                  <Link to={"/startups"}>Startups</Link>
                </li>
                <li className="uppercase cursor-pointer navLinkStyle">
                  <a href="/#news-section">news</a>
                </li>
                <li className="uppercase cursor-pointer navLinkStyle">
                  <a href="/#contact-section">contact</a>
                </li>
              </ul>
            </div>
            <div className="col-span-3 hidden md:flex items-center justify-between">
              {token ? (
                <ul className="space-x-3">
                  <button onClick={dashboard}>
                    <li className="">
                      <p
                        className={`text-white bg-[#4ac4f3] py-2 rounded-lg px-4`}
                      >
                        MyAccount
                      </p>
                    </li>
                  </button>
                  <button onClick={logout}>
                    <li className="">
                      <p
                        className={`text-[#4ac4f3] border border-[#4ac4f3] py-2 rounded-lg px-4`}
                      >
                        Logout
                      </p>
                    </li>
                  </button>
                </ul>
              ) : (
                <ul className="flex items-center justify-end space-x-4">
                  <Link to="/login">
                    <li className="border border-[#4ac4f3] text-[#4ac4f3] bg-white px-4 py-2 rounded-lg">
                      <p>Login</p>
                    </li>
                  </Link>
                  <Link to="/signup">
                    <li className="border-[#4ac4f3] bg-[#4ac4f3] text-white px-4 py-2 rounded-lg shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)]">
                      <p>Sign Up</p>
                    </li>
                  </Link>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
