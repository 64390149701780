export default function Cats(props) {
  return (
    <div className="flex md:block items-center space-x-3 md:space-x-0 border shadow rounded-lg relative group overflow-hidden transition-transform duration-300 ease-in-out transform hover:scale-105 p-4">
      <div className="w-16 h-16 rounded-full flex justify-center items-center overflow-hidden border border-gray-300">
        <img src={props.pic} alt="category" className="w-10 h-10" />
      </div>
      <div>
        <h1 className="md:mt-4 text-sm first-letter:capitalize">
          {props.title}
        </h1>
        <p className="mt-2 text-sm">{props.nbr} startups</p>
      </div>
    </div>
  );
}
