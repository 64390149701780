import React from "react";

import aventure from "../../assets/aventure.png";
import asf from "../../assets/asf.png";
import metro from "../../assets/metro.png";
import sonatrach from "../../assets/sonatrach.png";

export default function Partenaire() {
  return (
    <div id="products-section" className="my-10">
      <div className="flex items-center justify-center flex-col">
        <h1 className="text-4xl text-center">Our Partners</h1>
        <div className="flex items-center space-x-2 mb-4">
          <div className="h-[2px] mt-2 w-4 bg-[#4ac4f3] rounded-full"></div>
          <div className="h-[2px] mt-2 w-20 bg-[#4ac4f3] rounded-full"></div>
        </div>
        <p className="text-sm text-center ">Discover Our Beloved Startups</p>
      </div>

      <div className="flex items-center justify-center mt-2">
        <div className="flex flex-wrap items-center space-x-12">
          <img src={aventure} alt="aventure" className="w-12 object-contain" />
          <img src={asf} alt="aventure" className="w-12 object-contain" />
          <img src={sonatrach} alt="aventure" className="w-12 object-contain" />
          <img src={metro} alt="aventure" className="w-12 object-contain" />
          <img src={aventure} alt="aventure" className="w-12 object-contain" />
          <img src={asf} alt="aventure" className="w-12 object-contain" />
          <img src={sonatrach} alt="aventure" className="w-12 object-contain" />
          <img src={metro} alt="aventure" className="w-12 object-contain" />
        </div>
      </div>
    </div>
  );
}
