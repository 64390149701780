import React, { useEffect, useState } from "react";
// import './Home.css';
import bg from "../../assets/bg.png";
import YouTube from "react-youtube";
import vid from "../../assets/video.mp4";
import wave3 from "../../assets/wave3.png";
import rocket from "../../assets/rocket.png";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const onReady = (event) => {
    // Access to player in all event handlers via event.target
    setPlayer(event.target);
    event.target.playVideo();
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [player, setPlayer] = useState(null);

  const [email, setEmail] = useState("");

  useEffect(() => {
    localStorage.removeItem("email");
  }, []);

  const navigate = useNavigate();

  const handleGo = () => {
    localStorage.setItem("email", email);
    navigate("/signup");
  };

  return (
    <div
      className="relative home-section bg-[#4ac4f3] h-screen flex items-end w-full overflow-hidden"
      id="home-section"
    >
      <img src={wave3} alt="" className="w-full h-64 " />
      <img
        src={rocket}
        alt=""
        className="absolute right-0 md:right-10 top-1/2 -translate-y-1/2 w-[600px] h-[600px] object-cover"
      />
      <div className="absolute left-0 right-0 top-0 bottom-0">
        <div className="responsive flex justify-center flex-col h-full">
          <h1 className="md:text-white text-4xl md:text-5xl font-bold">
            You have a statrups
          </h1>
          <h3 className="md:text-white font-semibold md:font-light text-xl md:text-3xl mt-6">
            to show your project
          </h3>
          <p className="md:text-white text-sm md:text-lg font-semibold md:font-light mt-4 w-1/2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique,
            rem vitae aut consectetur animi culpa a. Voluptatem dignissimos
            deserunt aut.
          </p>
          <div className="p-2 rounded-full border shadow-md flex items-center w-fit mt-8 bg-white">
            <input
              type="text"
              className="outline-none placeholder:capitalize py-2 px-4"
              placeholder="your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p
              className="text-white bg-[#4ac4f3] rounded-full py-2 px-4 font-medium cursor-pointer"
              onClick={handleGo}
            >
              Sign up
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
