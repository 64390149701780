import React, { useEffect, useState } from "react";
/********** Components ****************/
import StartupPage from "../Components/startup/startup";
import NavBar from "../Components/NavBar/NavBar";
import ImgCover from "../assets/startup-page.png";
import Footer from "../Components/Footer/Footer";
import {
  faLocationDot,
  faPhone,
  faFax,
  faPaperPlane,
  faClock,
  faMailReply,
  faSeedling,
  faEnvelope,
  faSitemap,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faTiktok,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Main_API } from "../env";
import NavBarMobile from "../Components/NavBar/NavBarMobile";
import Img1 from "../assets/st1.png";
import Img2 from "../assets/st2.png";
import Img3 from "../assets/st3.png";

export default function StartupDetail(props) {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [search, setSearch] = useState("");

  const getstartups = async () => {
    try {
      const response = await axios.get(`${Main_API}/startup/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        setData(response.data.startup);
      }
    } catch (error) {
      console.error("Error fetching startup:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  useEffect(() => {
    localStorage.removeItem("email");
    getstartups();
  }, []);

  if (!data) return null;

  const date = new Date(data.created_at);

  // Get day in alphabetical format
  const day = date.getDate();

  // Get month in alphabetical format
  const month = date.toLocaleString("en-US", { month: "long" });

  let pic;
  if (data.id === 6) pic = Img1;
  else if (data.id === 7) pic = Img2;
  else if (data.id === 8) pic = Img3;

  return (
    <div>
      <div className="hidden md:block">
        <NavBar scrolling={props.scrolling} />
      </div>
      <div className="md:hidden">
        <NavBarMobile scrolling={props.scrolling} fixed />
      </div>

      <div>
        <img src={ImgCover} alt="" className="w-full h-[400px] object-cover" />
      </div>

      <div className="responsive grid md:grid-cols-3 my-20 gap-10">
        <div className="p-3 rounded-lg bg-white shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] relative md:col-span-2">
          <div className="relative">
            <img
              // src={`https://apitest.dernanedjilali.com/pictures/startups/${data.logo}`}
              src={`https://backend.delta-innovation.net/public/pictures/startups/${data.logo}`}
              alt=""
              className="w-full rounded-lg h-[300px] md:h-[500px] object-cover"
            />
            <div className="w-16 h-16 rounded-lg absolute overflow-hidden right-6 -bottom-6 bg-white shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">
              <p className="font-semibold text-2xl text-center h-10 flex items-center justify-center">
                {day}
              </p>
              <div className="bg-blue-500 w-full py-px flex items-center justify-center">
                <p className="uppercase text-white font-medium">
                  {month.slice(0, 3)}
                </p>
              </div>
            </div>
          </div>

          <h1 className="font-medium text-2xl mt-6 mb-0 first-letter:capitalize">
            {data.startup}
          </h1>
          <p className="text-gray-400 font-medium text-sm mb-4">
            @{data.fullname}
          </p>
          <p>{data.desc}</p>
        </div>

        <div className="w-full">
          <input
            type="text"
            placeholder="search..."
            className="w-full outline-none px-4 py-2 border border-blue-400 rounded-full"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <h3 className="text-2xl font-medium mt-4">Our product</h3>
          <div className="h-[2px] mt-2 w-[60px] bg-blue-500 rounded-full"></div>

          <div className="space-y-4 mt-8">
            {search
              ? data.services
                  .filter((item) => item.service_name.includes(search))
                  .map((item, idx) => (
                    <div
                      key={item}
                      className={`${idx < 5 - 1 && "border-b pb-4"} `}
                    >
                      <CardProd item={item} />
                    </div>
                  ))
              : data.services.map((item, idx) => (
                  <div
                    key={item}
                    className={`${idx < 5 - 1 && "border-b pb-4"} `}
                  >
                    <CardProd item={item} />
                  </div>
                ))}
          </div>

          <h3 className="text-2xl font-medium mt-10">Follow us</h3>
          <div className="h-[2px] mt-2 w-[60px] bg-blue-500 rounded-full"></div>
          <div className="mt-6 flex items-center space-x-4">
            <a
              href={data.socialmedia}
              target="_blank"
              className="bg-blue-400 rounded-lg flex items-center justify-center w-12 h-12 cursor-pointer z-20"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                className="text-xl text-white"
              />
            </a>

            <a
              href={`mailto:${data.email}`}
              className="bg-blue-400 rounded-lg flex items-center justify-center w-12 h-12 cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-xl text-white"
              />
            </a>

            <a
              href={`tel:+${data.phone}`}
              className="bg-blue-400 rounded-lg flex items-center justify-center w-12 h-12 cursor-pointer"
            >
              <FontAwesomeIcon icon={faPhone} className="text-xl text-white" />
            </a>

            <a
              href={data.website}
              target="_blank"
              className="bg-blue-400 rounded-lg flex items-center justify-center w-12 h-12 cursor-pointer"
            >
              <FontAwesomeIcon icon={faGlobe} className="text-xl text-white" />
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

const CardProd = ({ item }) => {
  const convertDate = (createdAt) => {
    const date = new Date(createdAt);

    const formattedDate = date.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };
  return (
    <div className="flex items-center space-x-4 ">
      <img
        src={`https://backend.delta-innovation.net/public/pictures/services/${item.picture}`}
        alt=""
        className="w-24 h-24 rounded-lg object-cover"
      />
      <div className="">
        <div className="flex items-center space-x-2">
          <FontAwesomeIcon className="text-sm text-gray-400" icon={faClock} />
          <p className="uppercase text-xs text-gray-400">
            {convertDate(item.created_at)}
          </p>
        </div>
        <h3 className="font-medium text-xl">{item.service_name}</h3>
        <p className="text-sm">{item.desc}</p>
      </div>
    </div>
  );
};
