import React from "react";

import at from "../../assets/at.png";
import logo2 from "../../assets/logo2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default function About() {
  return (
    <div className="my-10" id="about-section">
      <div className="flex items-center justify-center flex-col">
        <h1 className="text-xs md:text-lg text-[#4ac4f3]">Story About Us</h1>
        <p className="md:text-4xl font-bold text-center text-lg">
          Lorem ipsum dolor sit.
        </p>
        <ul className="gap-4 mt-8 grid md:grid-cols-2">
          {[1, 2, 3, 4].map((item) => (
            <li key={item} className="flex space-x-4">
              <FontAwesomeIcon
                className="text-2xl mt-1"
                style={{ color: "#4ac4f3" }}
                icon={faCheckCircle}
              />
              <div className="">
                <h4 className="text-sm md:text-lg font-semibold">
                  Lorem, ipsum dolor.
                </h4>
                <p className="text-gray-500 text-xs md:text-base mt-2">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Reiciendis, consequatur. Lorem ipsum dolor sit, amet
                  consectetur adipisicing elit. Earum dicta amet dignissimos
                  odit similique ea inventore autem sint quas ipsa.
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
