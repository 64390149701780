import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import Cats from "../includes/CategoryCard/Cats";
import cat from "../../assets/cat.png";
import cat1 from "../../assets/cat1.png";
import cat2 from "../../assets/cat2.png";
import cat3 from "../../assets/cat3.png";
import axios from "axios";
import { Main_API } from "../../env";
import { Link } from "react-router-dom";

export default function Categories() {
  const [allstartups, setAllstartups] = useState([]);

  const news = async () => {
    try {
      const response = await axios.get(`${Main_API}/categories`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        setAllstartups(response.data.categories);
      }
    } catch (error) {
      console.error("Error fetching startups:", error);
    }
  };

  useEffect(() => {
    news();
  }, []);

  return (
    <div className="mt-20 mb-20" id="cat-section">
      <div className="flex items-center justify-center flex-col">
        <h1 className="text-lg md:text-4xl text-center">Categories</h1>
        <div className="flex items-center space-x-2 mb-4">
          <div className="h-[2px] mt-2 w-4 bg-[#4ac4f3] rounded-full"></div>
          <div className="h-[2px] mt-2 w-20 bg-[#4ac4f3] rounded-full"></div>
        </div>
        <p className="text-xs md:text-sm text-center ">
          Discover Our Beloved Startups
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-8 my-8">
        {allstartups &&
          allstartups.map((item, idx) => (
            <Link to={`/startups?cat=${item.id}`}>
              <Cats
                key={idx}
                title={item.category}
                pic={`https://backend.delta-innovation.net/public/pictures/cats/${item.picture}`}
                nbr={item.startups}
              />
            </Link>
          ))}
      </div>
    </div>
  );
}
