export default function CardService(props) {
  return (
    <div
      className={`flex flex-col items-center shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px] bg-white rounded-lg relative group overflow-hidden transition-transform duration-300 ease-in-out transform hover:-translate-y-6 px-4 py-14 border-[#4ac4f3] ${
        props.color ? "border-b-2" : "hover:border-b-2"
      } `}
    >
      <div className="w-20 h-20 object-cover relative">
        <img src={props.img} className="w-full h-full object-cover" />
      </div>
      <div className="w-full mt-8">
        <h1 className="text-center font-medium text-lg md:text-xl">
          {props.title}
        </h1>
        <p className="text-center mt-2 text-xs md:text-sm text-gray-400">
          {props.desc}
        </p>
      </div>
    </div>
  );
}
