import React, { useEffect, useState } from "react";
import HeaderSection from "../includes/HeaderSection/HeaderSection";
import Card from "../includes/Card/Card";
import { Link } from "react-router-dom";
import axios from "axios";
import startup from "../../assets/startup.png";
import { Main_API } from "../../env";
// import newsBg from "../../assets/Wave-news.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Autoplay,
  Grid,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from "swiper/modules";

export default function News() {
  const [allstartups, setAllstartups] = useState([]);

  const news = async () => {
    try {
      const response = await axios.get(`${Main_API}/news/all`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        setAllstartups(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching startups:", error);
    }
  };

  console.log(allstartups);
  useEffect(() => {
    news();
  }, []);
  // console.log(allstartups)
  return (
    <div id="news-section" className="py-10 md:py-20">
      <div className="absolute top-1/2 -translate-x-1/2 left-0">
        {/* <img src={newsBg} alt="" /> */}
      </div>
      <div className="responsive">
        <div className="flex items-center justify-center flex-col">
          <h1 className="text-lg md:text-4xl text-center">Latest News</h1>
          <div className="flex items-center space-x-2 mb-4">
            <div className="h-[2px] mt-2 w-4 bg-blue-500 rounded-full"></div>
            <div className="h-[2px] mt-2 w-20 bg-blue-500 rounded-full"></div>
          </div>
          <p className="text-xs md:text-sm text-center ">
            Outlived no dwelling denoting in peculiar as he believed. Behaviour
            excellent middleton be as it curiosity departure ourselves very
            extreme future.
          </p>
        </div>

        <div className="mt-10"></div>
        <Swiper
          style={{ height: "100%" }}
          cssMode={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          spaceBetween={0}
          breakpoints={{
            // Define breakpoints and options for different screen sizes
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          // grid={{
          //   rows: 2,
          // }}
          mousewheel={true}
          keyboard={true}
          modules={[Autoplay, Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper"
        >
          {allstartups &&
            allstartups.length > 0 &&
            allstartups.map((item, key) => (
              <SwiperSlide className="pb-2 !h-full" key={item.id}>
                <CardNews
                  key={key}
                  pic={`https://backend.delta-innovation.net/public/pictures/news/${item.image_path}`}
                  delay="150"
                  title={item.title}
                  date={item.date}
                  desc={`${item.desc.slice(0, 100)}...`}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
}

const CardNews = (props) => {
  const date = new Date(props.date);

  // Get day in alphabetical format
  const day = date.getDate();

  // Get month in alphabetical format
  const month = date.toLocaleString("en-US", { month: "long" });

  return (
    <div className="aos-init aos-animate relative border">
      <div className="p-2 rounded-lg bg-white shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] relative">
        <div className="relative">
          <img
            src={props.pic}
            className="h-[200px] object-cover w-full rounded-lg border"
          />

          <div className="w-16 h-16 rounded-lg absolute overflow-hidden right-6 -bottom-6 bg-white shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">
            <p className="font-semibold text-2xl text-center h-10 flex items-center justify-center">
              {day}
            </p>
            <div className="bg-[#4ac4f3] w-full py-px flex items-center justify-center">
              <p className="uppercase text-white font-medium">
                {month.substring(0, 3)}
              </p>
            </div>
          </div>
        </div>

        <div className="px-6 py-4">
          <p className="text-xl font-medium first-letter:capitalize">
            {props.title}
          </p>
          <p className="desc text-sm text-gray-500 mt-2">{props.desc}</p>
        </div>
      </div>
    </div>
  );
};
