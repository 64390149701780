import React from "react";
import HeaderSection from "../includes/HeaderSection/HeaderSection";
import hosting from "../../assets/hosting.png";
import housing from "../../assets/housing.png";

export default function ComingSoon() {
  return (
    <div
      id="CommingSoon-section"
      className="pb-10 md:py-20 grid md:grid-cols-2 gap-x-10"
    >
      <div className="w-full h-[400px] hidden md:block">
        <img
          src={require("../../assets/cloud.png")}
          alt=""
          className="w-full h-full object-cover"
        />
      </div>
      <div className="flex flex-col justify-center">
        <h1 className="text-lg md:text-4xl text-center">
          Services Coming Soon
        </h1>

        <div className="md:mt-10 md:space-y-8">
          <div className="flex items-start space-x-4">
            <img
              src={require("../../assets/icon_hosting.png")}
              alt=""
              className="hidden md:block w-24 h-24 object-cover rounded-full"
            />
            <div>
              <div className="flex items-center space-x-4 pt-6">
                <h3 className="text-2xl font-semibold">Hosting</h3>
                <div className="bg-gray-200 text-xs px-2 font-medium capitalize py-[6px] w-fit relative">
                  <p className="relative z-20">comming soon</p>
                  <div className="bg-gray-200 absolute -left-1.5 top-1/2 -translate-y-1/2 w-3 h-3 rotate-45"></div>
                </div>
              </div>
              <p className="text-gray-700 text-sm mt-6">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Pariatur saepe repellat fugiat odit ipsa voluptatibus neque est
                dicta vitae iusto?
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-4">
            <img
              src={require("../../assets/icon_hosting.png")}
              alt=""
              className="hidden md:block w-24 h-24 object-cover rounded-full"
            />
            <div>
              <div className="flex items-center space-x-4 pt-6">
                <h3 className="text-2xl font-semibold">Housing</h3>
                <div className="bg-gray-200 text-xs px-2 font-medium capitalize py-[6px] w-fit relative">
                  <p className="relative z-20">comming soon</p>
                  <div className="bg-gray-200 absolute -left-1.5 top-1/2 -translate-y-1/2 w-3 h-3 rotate-45"></div>
                </div>
              </div>
              <p className="text-gray-700 text-sm mt-6">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Pariatur saepe repellat fugiat odit ipsa voluptatibus neque est
                dicta vitae iusto?
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
