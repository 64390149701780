import React, { useState, useEffect } from "react";
/********** Components ****************/
import StartupPage from "../Components/startup/startup";
import startup from "../assets/startup.png";
import NavBar from "../Components/NavBar/NavBar";
import HeaderSection from "../Components/includes/HeaderSection/HeaderSection";
import Footer from "../Components/Footer/Footer";
import Card from "../Components/includes/Card/Card";
import { Link } from "react-router-dom";
import { Main_API } from "../env";
import axios from "axios";
import Img1 from "../assets/st1.png";
import Img2 from "../assets/st2.png";
import Img3 from "../assets/st3.png";

export default function StartupsPage(props) {
  const [allstartups, setAllstartups] = useState([]);

  useEffect(() => {
    localStorage.removeItem("email");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const getstartups = async () => {
    try {
      const response = await axios.get(`${Main_API}/startups`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        setAllstartups(response.data.startups);
      }
    } catch (error) {
      console.error("Error fetching startups:", error);
    }
  };

  const getstartupsByCat = async (id) => {
    try {
      const response = await axios.get(`${Main_API}/cat/startup/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        setAllstartups(response.data.startups);
      }
    } catch (error) {
      console.error("Error fetching startups:", error);
    }
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const catValue = searchParams.get("cat");
    if (catValue) getstartupsByCat(catValue);
    else getstartups();
  }, []);

  return (
    <div>
      <NavBar scrolling={props.scrolling} />

      <div className="responsive">
        <div className="flex items-center justify-center flex-col mt-10">
          <h1 className="text-4xl text-center">
            Discover Our Range of Startups
          </h1>
          <div className="flex items-center space-x-2 mb-4">
            <div className="h-[2px] mt-2 w-4 bg-blue-500 rounded-full"></div>
            <div className="h-[2px] mt-2 w-20 bg-blue-500 rounded-full"></div>
          </div>
          <p className="text-sm text-center ">
            Explore our portfolio of innovative startups.
          </p>
        </div>

        <div className=" grid grid-cols-3 gap-8  my-20">
          {allstartups &&
            allstartups.length > 0 &&
            allstartups.slice(2).map((item, key) => {
              let pic = Img1;
              if (key === 1) pic = Img2;
              else if (key === 2) pic = Img3;
              return (
                <Link to={`/startup/${item.id}`}>
                  <Card
                    // pic={`https://apitest.dernanedjilali.com/pictures/startups/${item.logo}`}
                    pic={pic}
                    delay="150"
                    title={item.startup}
                    desc={`${item.desc.slice(0, 100)}...`}
                    item={item}
                  />
                </Link>
              );
            })}
        </div>
      </div>

      <Footer />
    </div>
  );
}
