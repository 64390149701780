import React, { useEffect, useState } from "react";
/********** Components ****************/
import NavBar from "../Components/NavBar/NavBar";
import About from "../Components/About/About";
import Startups from "../Components/Other/Startups";
import Partenaire from "../Components/Partenaire/Partenaire";
import Footer from "../Components/Footer/Footer";
import Services from "../Components/Services/Services";
import Categories from "../Components/Categories/Categories";
import Word from "../Components/Word/Word";
import News from "../Components/Other/News";
import Talk from "../Components/Other/Talk";
import vid from "../assets/video.mp4";
import wave1 from "../assets/oooscillate.svg";
import ScrollSection from "../Components/includes/ScrollSection";
import CommingSoon from "../Components/ComingSoon/ComingSoon";
import HeroSection from "../Components/HeroSection/HeroSection";
import NavBarMobile from "../Components/NavBar/NavBarMobile";

export default function LandingPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <div>
      <div className="hidden md:block">
        <NavBar scrolling={props.scrolling} />
      </div>
      <div className="md:hidden">
        <NavBarMobile scrolling={props.scrolling} fixed />
      </div>

      <HeroSection />

      <div className="relative">
        <div className="absolute left-0 right-0 wave1 opacity-40"></div>
        <div className="responsive relative z-20">
          <About />
          <Services />
          <CommingSoon />
        </div>
      </div>

      <ScrollSection />

      <div className="relative">
        <div className="absolute left-0 right-0 wave1 opacity-40 rotate-0"></div>
        <div className="responsive relative z-20">
          <Categories />
        </div>
      </div>

      {/* <div className="responsive">
        <Categories />
      </div> */}
      <Startups />
      <div className="responsive">
        <Word />
      </div>

      <div className="relative bg">
        <div className="relative z-20 bg-[#4ac4f3] bg-opacity-5">
          <News />
        </div>
      </div>

      <div className="responsive">
        <Talk />
        <Partenaire />
      </div>

      <Footer />
    </div>
  );
}
