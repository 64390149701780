import React, { useEffect, useState } from "react";
import cover from "../../assets/cloud.png";
import vid from "../../assets/video.mp4";

const ScrollSection = () => {
  return (
    <div class="scroll-section-container">
      <video
        autoPlay
        loop
        className="h-[500px] w-full object-cover brightness-[.7] bg-blue-500 video-background"
      >
        <source src={vid} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute -left-5 -right-5 -top-14 bg-white h-[120px] rotate-[4deg]"></div>
      <div className="absolute -left-5 -right-5 -bottom-8 md:-bottom-14 bg-white h-[50px] md:h-[120px] rotate-[4deg]"></div>
    </div>
    // <section className="scroll-section-container relative mb-20">
    // </section>
    // <section className="scroll-section-container relative mb-20">
    //   <p>lsdf</p>
    //   <p>lsdf</p>
    //   <p>lsdf</p>
    //   <p>lsdf</p>
    //   <p>lsdf</p>
    //   <p>lsdf</p>
    //   <p>lsdf</p>
    //   <div className="absolute -left-5 -right-5 -top-14 bg-white h-[120px] rotate-[4deg]"></div>
    //   <div className="absolute -left-5 -right-5 -bottom-14 bg-white h-[120px] rotate-[4deg]"></div>
    // </section>
  );
};

export default ScrollSection;
