import React from "react";
// import './Footer.css';
import logoWhite from "../../assets/logo-white.png";
import qr from "../../assets/Qrcode.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faFax,
  faPaperPlane,
  faChevronRight,
  faArrowRight,
  faHome,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebook,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <div
      id="footer-section"
      className="relative bg-[#212942] text-white pt-20 pb-4"
    >
      <div className="w-full hidden md:grid grid-cols-9 absolute left-0 right-0 top-0 bottom-0">
        <div className="w-full col-span-6 bg-[#212942]"></div>
        <div className="w-full col-span-3 bg-[#293354] "></div>
      </div>
      <div className="relative z-30 responsive grid grid-cols-3 md:grid-cols-9 footer-section-top md:gap-32 xs:gap-12">
        <div className="col-span-3 footer-section-newsletter md:border-r w-fit md:pr-8 border-gray-500 flex md:block flex-col items-center">
          <img src={logoWhite} />
          <p className="pt-4 mb-2 text-sm my-4 text-center md:text-left">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae non
            veritatis possimus alias nemo blanditiis.
          </p>
          <div className="space-x-6 w-fit mt-4 rounded-lg text-xl aos-init aos-animate flex items-center justify-center bg-[#293354] border border-gray-600 overflow-hidden">
            <input
              type="email"
              placeholder="Enter your email "
              className="pl-4 text-sm bg-transparent outline-none py-3"
            />
            <button className="">
              <FontAwesomeIcon
                className="send text-blue-500 mr-4 border-l pl-4 border-gray-500"
                icon={faArrowRight}
              />
            </button>
          </div>
        </div>

        <div className="col-span-3 flex items-center justify-between">
          <div className="footer-section-contact">
            <h1 className="mb-4 text-white">Quik Links</h1>
            <ul className="space-y-2">
              <li className="flex items-center space-x-3">
                <FontAwesomeIcon
                  className="pr-2"
                  style={{ color: "white" }}
                  icon={faChevronRight}
                />
                <p>Home</p>
              </li>
              <li className="flex items-center space-x-3">
                <FontAwesomeIcon
                  className="pr-2"
                  style={{ color: "white" }}
                  icon={faChevronRight}
                />

                <p>About us</p>
              </li>
              <li className="flex items-center space-x-3">
                <FontAwesomeIcon
                  className="pr-2"
                  style={{ color: "white" }}
                  icon={faChevronRight}
                />

                <p>Services</p>
              </li>
              <li className="flex items-center space-x-3">
                <FontAwesomeIcon
                  className="pr-2"
                  style={{ color: "white" }}
                  icon={faChevronRight}
                />

                <p>Startups</p>
              </li>
              <li className="flex items-center space-x-3">
                <FontAwesomeIcon
                  className="pr-2"
                  style={{ color: "white" }}
                  icon={faChevronRight}
                />

                <p>Categories</p>
              </li>
              <li className="flex items-center space-x-3">
                <FontAwesomeIcon
                  className="pr-2"
                  style={{ color: "white" }}
                  icon={faChevronRight}
                />

                <p>Contact</p>
              </li>
            </ul>
          </div>
          <div className="footer-section-contact">
            <h1 className="mb-4 text-white">Quik Links</h1>
            <ul className="space-y-2">
              <li className="flex items-center space-x-3">
                <FontAwesomeIcon
                  className="pr-2"
                  style={{ color: "white" }}
                  icon={faChevronRight}
                />
                <p>Home</p>
              </li>
              <li className="flex items-center space-x-3">
                <FontAwesomeIcon
                  className="pr-2"
                  style={{ color: "white" }}
                  icon={faChevronRight}
                />

                <p>About us</p>
              </li>
              <li className="flex items-center space-x-3">
                <FontAwesomeIcon
                  className="pr-2"
                  style={{ color: "white" }}
                  icon={faChevronRight}
                />

                <p>Services</p>
              </li>
              <li className="flex items-center space-x-3">
                <FontAwesomeIcon
                  className="pr-2"
                  style={{ color: "white" }}
                  icon={faChevronRight}
                />

                <p>Startups</p>
              </li>
              <li className="flex items-center space-x-3">
                <FontAwesomeIcon
                  className="pr-2"
                  style={{ color: "white" }}
                  icon={faChevronRight}
                />

                <p>Categories</p>
              </li>
              <li className="flex items-center space-x-3">
                <FontAwesomeIcon
                  className="pr-2"
                  style={{ color: "white" }}
                  icon={faChevronRight}
                />

                <p>Contact</p>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-section-newsletter col-span-3">
          <h2 className="capitalize font-semibold mb-6 text-lg text-center md:text-left">
            contact info
          </h2>

          <div className="space-y-8">
            <div className="flex items-center space-x-4">
              <div className="w-14 h-14 rounded-full flex items-center justify-center bg-gray-700">
                <FontAwesomeIcon className="send text-white" icon={faHome} />
              </div>
              <div>
                <h3 className="capitalize font-medium">address:</h3>
                <p>Lorem, ipsum dolor.</p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <div className="w-14 h-14 rounded-full flex items-center justify-center bg-gray-700">
                <FontAwesomeIcon
                  className="send text-white"
                  icon={faEnvelope}
                />
              </div>
              <div>
                <h3 className="capitalize font-medium">Email:</h3>
                <p>Lorem, ipsum dolor.</p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <div className="w-14 h-14 rounded-full flex items-center justify-center bg-gray-700">
                <FontAwesomeIcon className="send text-white" icon={faPhone} />
              </div>
              <div>
                <h3 className="capitalize font-medium">phone:</h3>
                <p>Lorem, ipsum dolor.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-20 text-white md:grid grid-cols-3 relative z-30 responsive">
        <div className="md:col-span-2 border-t border-gray-700 pt-4">
          <p className="text-center">
            © Copyright 2023. All Rights Reserved by{" "}
            <span className="text-blue-500 font-medium">algerie telecom</span>
          </p>
          <ul className="flex items-center space-x-3 justify-center">
            <li>Terms</li>
            <li>Support</li>
            <li>Privacy</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
