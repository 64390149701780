import React from "react";
import { Link } from "react-router-dom";
import IcMenu from "../../../assets/ic_menu.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faLocationDot } from "@fortawesome/free-solid-svg-icons";

export default function Card(props) {
  const convertDate = (createdAt) => {
    const date = new Date(createdAt);

    const formattedDate = date.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });

    return formattedDate;
  };
  return (
    <div className="aos-init aos-animate relative">
      <div className="p-3 rounded-lg bg-white shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] relative">
        <div className="border rounded-lg border-gray-300">
          <img
            src={props.pic}
            className="border border-gray-400 h-[250px] object-cover w-full rounded-lg"
          />
        </div>

        {/* <div className="w-12 h-12 rounded-lg p-1 absolute right-6 rotate-45 -bottom-6 bg-white shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">
            <div className="bg-gradient-to-r from-blue-500 to-blue-800 w-full h-full rounded-lg flex items-center justify-center">
            <img src={IcMenu} alt="" className="w-6 h-6 -rotate-45" />
            </div>
          </div> */}

        <Link to={`/startups/${props.item.id}`}>
          <h3 className="text-xl font-medium first-letter:capitalize mt-4 hover:text-[#4ac4f3] mb-2">
            {props.title}
          </h3>
        </Link>
        <div className="flex items-center space-x-2 text-sm text-gray-400">
          <FontAwesomeIcon icon={faLocationDot} />
          <p>{props.item.wilaya}</p>
        </div>
        <div className="flex items-center space-x-2 text-sm text-gray-400">
          <FontAwesomeIcon icon={faCalendar} />
          <p>{convertDate(props.item.creation_date)}</p>
        </div>
        <p className="desc text-sm text-gray-500 mt-4">{props.desc}</p>
      </div>
      <div className="px-6 py-4"></div>
    </div>
  );
}
