import React, { useEffect, useState } from "react";
import HeaderSection from "../includes/HeaderSection/HeaderSection";
import Card from "../includes/Card/Card";
import { Link } from "react-router-dom";
import axios from "axios";
import startup from "../../assets/startup.png";
import { Main_API } from "../../env";
import {
  faLocationDot,
  faPhone,
  faFax,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { useToast } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";

export default function Talk() {
  const [allstartups, setAllstartups] = useState([]);

  const toast = useToast();
  const [loading, setLoading] = useState(false);

  // add new data
  const { register, handleSubmit, reset, setValue, getValues } = useForm({});

  const sendMsg = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(`${Main_API}/message/store`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        toast({
          title: "Message sended.",
          description: "We've send a message for you.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        reset();
        setLoading(false);
      }
    } catch (error) {
      console.error("Error send msg:", error);
      setLoading(false);
    }
  };

  // console.log(allstartups)
  return (
    <div id="contact-section" className="py-10 md:py-20">
      <div className="responsive grid md:grid-cols-2 gap-24">
        <div className="flex items-center justify-center flex-col">
          <h1 className="text-4xl text-center">Let's talk?</h1>
          <div className="flex items-center space-x-2 mb-4">
            <div className="h-[2px] mt-2 w-4 bg-[#4ac4f3] rounded-full"></div>
            <div className="h-[2px] mt-2 w-20 bg-[#4ac4f3] rounded-full"></div>
          </div>
          <p className="text-sm text-center ">
            It's all about the humans behind a brand and those experiencing it,
            we're right there. In the middle.
          </p>

          <div className="flex items-start mt-10 justify-between w-5/6 md:w-2/3">
            <div className="flex flex-col items-center">
              <div className="bg-blue-300 bg-opacity-40 w-[86px] h-[86px] rounded-full flex items-center justify-center">
                <div className="bg-[#4ac4f3] flex items-center justify-center rounded-full w-[65px] h-[65px]">
                  <FontAwesomeIcon
                    className="text-2xl"
                    style={{ color: "white" }}
                    icon={faLocationDot}
                  />
                </div>
              </div>
              <p className="text-gray-500 text-center mt-2 text-xs md:text-base">
                address alger
              </p>
            </div>
            <div className="w-px h-36 bg-gray-300"></div>
            <div className="flex flex-col items-center">
              <div className="bg-blue-300 bg-opacity-40 w-[86px] h-[86px] rounded-full flex items-center justify-center">
                <div className="bg-[#4ac4f3] flex items-center justify-center rounded-full w-[65px] h-[65px]">
                  <FontAwesomeIcon
                    className="text-2xl"
                    style={{ color: "white" }}
                    icon={faLocationDot}
                  />
                </div>
              </div>
              <a
                href="tel:+"
                className="text-gray-500 text-center mt-2 text-xs md:text-base"
              >
                +213 90234234
              </a>
              <a
                href="tel:+"
                className="text-gray-500 text-center mt-2 text-xs md:text-base"
              >
                +213 90234234
              </a>
            </div>
          </div>
        </div>

        <div className="w-full">
          <form
            onSubmit={handleSubmit(sendMsg)}
            className="grid grid-cols-2 gap-6"
          >
            <input
              type="text"
              className="bg-gray-100 rounded-md px-4 py-4 outline-none placeholder:capitalize col-span-2"
              placeholder="Name*"
              {...register("name", { required: true })}
            />
            <input
              type="email"
              name=""
              id=""
              placeholder="email*"
              {...register("email", { required: true })}
              className="bg-gray-100 rounded-md px-4 py-4 outline-none placeholder:capitalize"
            />
            <input
              type="tel"
              name=""
              id=""
              placeholder="phone*"
              {...register("phone", { required: true })}
              className="bg-gray-100 rounded-md px-4 py-4 outline-none placeholder:capitalize"
            />
            <textarea
              name=""
              id=""
              placeholder="tell more about us*"
              cols="30"
              rows="10"
              {...register("msg", { required: true })}
              className="col-span-2 w-full bg-gray-100 rounded-md px-4 py-4 outline-none placeholder:capitalize"
            ></textarea>
            <button
              disabled={loading}
              onClick={sendMsg}
              type="submit"
              className="bg-[#4ac4f3] rounded-md px-6 py-4 text-white font-medium flex items-center justify-center space-x-2 capitalize text-sm md:text-base col-span-2 md:col-span-1"
            >
              {loading ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="md"
                />
              ) : (
                <div className="flex items-center justify-center space-x-4">
                  <p>send message</p>

                  <FontAwesomeIcon
                    className="pr-2"
                    style={{ color: "white" }}
                    icon={faPaperPlane}
                  />
                </div>
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
