import React, { useState, useEffect } from "react";
import logo from "../../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons"; // Replace with the specific icon you want to use
import { Main_API } from "../../env";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
} from "@chakra-ui/react";

export default function NavBarMobile(props) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [Active, setActive] = useState(false);
  const [token, setToken] = useState("");
  useEffect(() => {
    const item = JSON.parse(localStorage.getItem("token"));
    if (item) {
      setToken(item);
    }
  }, []);
  const handleClick = () => {
    setActive(!Active);
  };
  const logout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };
  const dashboard = () => {
    window.location.href =
      "https://apitest.dernanedjilali.com/startup/" + token;
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 400);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navbarClass = isScrolled || props.fixed ? "fixed-navbar" : "";

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
      <div className={`${navbarClass} border-b`}>
        {/* <TopHeader /> */}
        <div className="flex items-center justify-between responsive bg-white shadow-md">
          <Link to={"/"} className="col-span-3 flex items-center space-x-2">
            <img className={`w-20 h-20 object-contain`} src={logo} alt="logo" />
          </Link>

          <button ref={btnRef} colorScheme="teal" onClick={onOpen}>
            <FontAwesomeIcon
              className="pr-2"
              style={{ color: "#4ac4f3" }}
              icon={faBars}
            />
          </button>
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
            size={"sm"}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Create your account</DrawerHeader>

              <DrawerBody>
                <ul className="w-full space-y-4 font-medium text-gray-700 text-sm">
                  <li className="uppercase cursor-pointer navLinkStyle">
                    <a href="http://localhost:3000/#home-section">Home</a>
                  </li>
                  <li className="uppercase cursor-pointer navLinkStyle">
                    <a href="http://localhost:3000/#about-section">About</a>
                  </li>
                  <li className="uppercase cursor-pointer navLinkStyle">
                    <a href="http://localhost:3000/#services-section">
                      Services
                    </a>
                  </li>
                  <li className="uppercase cursor-pointer navLinkStyle">
                    <a href="http://localhost:3000/#cat-section">Categories</a>
                  </li>

                  <li className="uppercase cursor-pointer navLinkStyle">
                    <Link to={"/startups"}>Startups</Link>
                  </li>
                  <li className="uppercase cursor-pointer navLinkStyle">
                    <a href="http://localhost:3000/#news-section">news</a>
                  </li>
                  <li className="uppercase cursor-pointer navLinkStyle">
                    <a href="http://localhost:3000/#contact-section">contact</a>
                  </li>
                </ul>
              </DrawerBody>

              <DrawerFooter>
                <div className="w-full">
                  {token ? (
                    <ul className="grid grid-cols-2 gap-4">
                      <button onClick={dashboard}>
                        <li className="mb-4">
                          <p
                            className={`text-white bg-[#4ac4f3] py-2 rounded-lg`}
                          >
                            MyAccount
                          </p>
                        </li>
                      </button>
                      <button onClick={logout}>
                        <li className="">
                          <p
                            className={`text-[#4ac4f3] border border-[#4ac4f3] py-2 rounded-lg `}
                          >
                            Logout
                          </p>
                        </li>
                      </button>
                    </ul>
                  ) : (
                    <ul className="space-y-4">
                      <Link to="/login">
                        <li className="border border-[#4ac4f3] text-[#4ac4f3] bg-white px-4 py-2 rounded-lg mb-4">
                          <p>Login</p>
                        </li>
                      </Link>
                      <Link to="/signup">
                        <li className="border-[#4ac4f3] bg-[#4ac4f3] text-white px-4 py-2 rounded-lg shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)]">
                          <p>Sign Up</p>
                        </li>
                      </Link>
                    </ul>
                  )}
                </div>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </div>
      </div>
    </>
  );
}
