import React, { useState, useEffect } from "react";
import NavBar from "../Components/NavBar/NavBar";
import at from "../assets/logo.png";
import forstartup from "../assets/forstartup.png";
import { Link, useNavigate } from "react-router-dom";
import { Main_API } from "../env";
import axios from "axios";
import rocket from "../assets/rocket.png";

export default function Signup(props) {
  const navigate = useNavigate();
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState(localStorage.getItem("email") ?? "");
  const [phone, setPhone] = useState("");
  const [startup, setStartup] = useState("");
  const [password, setPassword] = useState("");
  const [catid, setCatid] = useState(null);
  const [wilaya, setWilaya] = useState("");
  const [desc, setDesc] = useState("");
  const [label, setLabel] = useState("");
  const [creation_date, setCreation_date] = useState("");
  const [website, setWebsite] = useState("");
  const [socialmedia, setSocialmedia] = useState("");
  const [categories, setCategories] = useState([]);
  const [logo, setLogo] = useState();

  const Register = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("logo", logo);
    formData.append("fullname", fullname);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("startup", startup);
    formData.append("password", password);
    formData.append("catid", catid);
    formData.append("wilaya", wilaya);
    formData.append("desc", desc);
    formData.append("label", label);
    formData.append("creation_date", creation_date);
    formData.append("website", website);
    formData.append("socialmedia", socialmedia);

    axios
      .post(Main_API + "/register", formData)
      .then((response) => {
        //console.log(response)
        window.location.href = "/login";
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCategories = async () => {
    try {
      const response = await axios.get(`${Main_API}/categories`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        setCategories(response.data.categories);
        setCatid(response.data.categories[0].id);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);
  const handleFileChange = (e) => {
    setLogo(e.target.files[0]);
  };

  return (
    <div>
      <NavBar scrolling={props.scrolling} fixed />
      <div className="h-screen pt-20 grid rid-cols-1 justify-center items-center align-center relative overflow-hidden">
        <div className="absolute left-0 right-0 wave1 opacity-40"></div>
        <img
          src={rocket}
          alt=""
          className="absolute right-10 top-1/2 -translate-y-1/2 w-[600px] h-[600px] object-cover"
        />

        <div className="z-30 bg-white h-fit backdrop-blur-2xl bg-opacity-30 shadow-xl p-6 rounded-lg">
          <h1 className="text-4xl p-2 font-medium text-center">
            Create Startup Account
          </h1>
          <p className="text-gray-400 text-center">
            Sign up and we will contact you to fill more informations
          </p>

          <form className="grid md:grid-cols-2 gap-4 mt-4 overflow-y-scroll h-[270px] md:pr-4">
            <div className="">
              <input
                type="text"
                name="fullname"
                placeholder="Nom Complet "
                className="rounded-full px-4 py-3 w-full bg-white border outline-none"
                required={true}
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
              />
            </div>
            <div>
              <input
                type="email"
                name="email"
                placeholder="Email "
                required
                className="rounded-full px-4 py-3 w-full bg-white border outline-none"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <input
                type="password"
                name="password"
                required
                placeholder="Mot de Passe"
                className="rounded-full px-4 py-3 w-full bg-white border outline-none"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                name="startup"
                placeholder="Nom de Startup "
                required
                className="rounded-full px-4 py-3 w-full bg-white border outline-none"
                value={startup}
                onChange={(e) => setStartup(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                name="wilaya"
                placeholder="Wilaya "
                required
                className="rounded-full px-4 py-3 w-full bg-white border outline-none"
                value={wilaya}
                onChange={(e) => setWilaya(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                name="phone"
                placeholder="Numero Telephone "
                required
                className="rounded-full px-4 py-3 w-full bg-white border outline-none"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                name="label"
                placeholder="Label "
                required
                className="rounded-full px-4 py-3 w-full bg-white border outline-none"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              />
            </div>
            <div>
              <input
                type="date"
                name="creation_date"
                placeholder="Date de Creation  "
                required
                value={creation_date}
                className="rounded-full px-4 py-3 w-full bg-white border outline-none"
                onChange={(e) => setCreation_date(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                name="website"
                placeholder="Lien du site web  "
                required
                className="rounded-full px-4 py-3 w-full bg-white border outline-none"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>
            <div>
              <input
                type="file"
                onChange={handleFileChange}
                required
                className="bg-[#4ac4f3] text-white px-8 py-2 rounded-lg"
              />
            </div>
            <div>
              <input
                type="text"
                name="socialmedia"
                placeholder="Lien du Social Media "
                required
                className="rounded-full px-4 py-3 w-full bg-white border outline-none"
                value={socialmedia}
                onChange={(e) => setSocialmedia(e.target.value)}
              />
            </div>
            <div>
              <select
                className="rounded-full px-4 py-3 w-full bg-white border outline-none"
                value={catid}
                onChange={(e) => setCatid(e.target.value)}
                required
              >
                {categories &&
                  categories.length > 0 &&
                  categories.map((item, key) => (
                    <option key={key} value={item.id}>
                      {item.category}
                    </option>
                  ))}
              </select>
            </div>
            <div className="md:col-span-2">
              <textarea
                className="rounded-lg px-4 py-3 w-full bg-white border outline-none"
                placeholder="description "
                value={desc}
                required
                onChange={(e) => setDesc(e.target.value)}
              ></textarea>
            </div>
          </form>
          <div className="flex items-center justify-center">
            <button
              className="bg-[#4ac4f3] text-white px-8 py-2 rounded-lg mt-4"
              onClick={Register}
              type="submit"
            >
              Create Account
            </button>
          </div>
          <Link to="/login">
            <p className={`text-sm hover:underline text-center py-2`}>
              Already have an account
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}
