import React, { useEffect, useState } from "react";
import HeaderSection from "../includes/HeaderSection/HeaderSection";
import Card from "../includes/Card/Card";
import { Link } from "react-router-dom";
import axios from "axios";
import startup from "../../assets/startup.png";
import { Main_API } from "../../env";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";
import Img1 from "../../assets/st1.png";
import Img2 from "../../assets/st2.png";
import Img3 from "../../assets/st3.png";

export default function Startups() {
  const [allstartups, setAllstartups] = useState([]);

  const getstartups = async () => {
    try {
      const response = await axios.get(`${Main_API}/startups`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        setAllstartups(response.data.startups);
      }
    } catch (error) {
      console.error("Error fetching startups:", error);
    }
  };

  // const getstartupsByCat = async (id) => {
  //   try {
  //     const response = await axios.get(`${Main_API}/cat/startup/${id}`, {
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     if (response.data.success) {
  //       setAllstartups(response.data.startups);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching startups:", error);
  //   }
  // };

  useEffect(() => {
    // const url = new URL(window.location.href);
    // const searchParams = new URLSearchParams(url.search);
    // const catValue = searchParams.get("cat");
    // if (catValue) getstartupsByCat(catValue);
    // else
    getstartups();
  }, []);

  // useEffect(() => {
  //   console.log(allstartups);
  // }, []);
  // console.log(allstartups)
  return (
    <div
      id="startups-section"
      className="py-10 md:py-20 bg-[#4ac4f3] bg-opacity-5"
    >
      <div className="responsive">
        <div className="flex items-center justify-center flex-col">
          <h1 className="text-lg md:text-4xl text-center">
            Discover Our Range of Startups
          </h1>
          <div className="flex items-center space-x-2 mb-4">
            <div className="h-[2px] mt-2 w-4 bg-[#4ac4f3] rounded-full"></div>
            <div className="h-[2px] mt-2 w-20 bg-[#4ac4f3] rounded-full"></div>
          </div>
          <p className="text-xs md:text-sm text-center ">
            Explore our portfolio of innovative startups
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-x-16 mt-8 w-full">
          {allstartups &&
            allstartups.length > 0 &&
            allstartups.map((item, key) => {
              return (
                <Card
                  key={key}
                  // pic={`https://apitest.dernanedjilali.com/pictures/startups/${item.logo}`}
                  pic={`https://backend.delta-innovation.net/public/pictures/startups/${item.logo}`}
                  // pic={pic}
                  delay="150"
                  title={item.startup}
                  item={item}
                  desc={`${item.desc.slice(0, 100)}...`}
                />
              );
            })}
        </div>
        <div className="w-full flex items-center justify-center mt-8">
          <Link to="/startups">
            <button className="px-4 py-2 bg-[#4ac4f3] text-xl rounded-md text-white font-medium">
              See More
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
