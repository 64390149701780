import React, { useEffect } from "react";
import logo from "../../assets/logo.png";
import dg from "../../assets/dg.png";
import AOS from "aos";
import "aos/dist/aos.css";
export default function Word() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      offset: 160, // Adjust the offset as needed
    });
  }, []);
  return (
    <div className="my-10 md:grid grid-cols-2 gap-x-14">
      <div className="flex space-x-4 items-center flex-col md:flex-row">
        <div className="md:!min-w-fit !w-32 !h-32 p-2 rounded-full bg-white shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">
          <img
            src={dg}
            alt=""
            className="mb-4 w-full h-full object-cover rounded-full"
          />
        </div>
        <div className="hidden md:block h-20 w-[1px] bg-gray-200"></div>
        <div>
          <p className="text-sm w-full text-center md:text-left mt-6 md:mt-0">
            Algérie Télécom est une société par actions à capitaux publics
            opérant dans le domaine des télécommunications, de la téléphonie
            fixe et d’internet. Sa naissance a été consacrée par la loi 2000/03
            du 5 août 2000,
          </p>
          <h1 className="text-2xl font-semibold mt-4 text-center md:text-left">
            Adel Bentoumi
          </h1>
          <p className="text-[#4ac4f3] font-medium text-xs text-center md:text-left">
            Président Directeur Général
          </p>
        </div>
      </div>
      <div className="hidden md:block">
        <img
          src={require("../../assets/words.png")}
          alt=""
          className="w-full h-full"
        />
      </div>
    </div>
  );
}
